$hacker-blue: #11182c;
$dark-blue-grey: #50595f;
$light-blue-grey: #c7c9d2;
$off-white: #f5f5f5;
$bright-blue: #005a9b;

$text-base: 16px;

$font-size-bigger: 3.125rem;
$font-size-big: 1.875rem;
$font-size-large: 1.125rem;
$font-size-body: 1rem;
$font-size-smaller: 0.875rem;
