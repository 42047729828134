.hp-instructions {
  align-self: flex-start;
  margin-top: 20px;
}

.hp-instructions__heading {
  background: $hacker-blue;
  font-size: $font-size-smaller;
  border-radius: 5px;
  display: inline-block;
  padding: 7px 14px;
  font-weight: 400;
  margin: 0;

  a {
    text-decoration: none;
    color: white;
  }
}

.hp-instructions__list {
  --number-size: 30px;
  list-style: none;
  counter-reset: my-awesome-counter;
  position: relative;
  padding: 0;
  margin-bottom: 40px;
  margin-left: 27px;
  margin-top: 30px;

  &::before {
    content: "";
    position: absolute;
    top: -30px;
    left: calc(var(--number-size) / 2);
    bottom: -40px;
    border-left: 2px solid $hacker-blue;
  }

  &::after {
    content: "";
    background-color: white;
    width: 10px;
    height: 10px;
    border: 2px solid $hacker-blue;
    position: absolute;
    bottom: -40px;
    border-radius: 50%;
    left: 11px;
  }
}

.hp-instructions__list-item {
  counter-increment: my-awesome-counter;
  font-size: $font-size-smaller;
  line-height: 1.43;
  padding-left: calc(var(--number-size) + 15px);
  position: relative;
  min-height: 40px;
  display: flex;
  align-items: center;
  max-width: 450px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &::before {
    content: counter(my-awesome-counter);
    border: 2px solid $hacker-blue;
    border-radius: 50%;
    display: inline-block;
    width: var(--number-size);
    height: var(--number-size);
    line-height: calc(var(--number-size) - 4px);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: white;
  }
}

@media (min-width: 800px) {
  .hp-instructions {
    margin-left: 100px;
    margin-top: 40px;
  }
  .hp-instructions__list {
    --number-size: 40px;
    margin-bottom: 60px;

    &::after {
      left: 16px;
      bottom: -60px;
    }

    &::before {
      bottom: -60px;
    }
  }

  .hp-instructions__heading {
    font-size: $font-size-large;
  }

  .hp-instructions__list-item {
    font-size: $font-size-large;
    padding-left: calc(var(--number-size) + 20px);
    min-height: 50px;
  }
}
