.text-hero {
  font-size: $font-size-big;
  font-weight: 900;
  max-width: 350px;
  line-height: 1.25;
  margin: 30px 0 0;

  .text-heading + & {
    margin-top: 0;
  }
}

.text-heading {
  text-transform: uppercase;
  color: $dark-blue-grey;
  font-size: $font-size-smaller;
  margin: 20px 0 0;
  line-height: 1.66;
}

.text-body {
  max-width: 600px;
  line-height: 1.875;
}

@media (min-width: 800px) {
  .text-body {
    font-size: $font-size-large;
  }

  .text-hero {
    font-size: 5rem;
    max-width: 650px;
  }

  .text-heading {
    font-size: 1.125rem;
    margin-top: 60px;
  }
}
