.hp-tee-list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 40px;

  @media (min-width: 700px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 60px;
  }
}

.hp-tee-list__item {
  position: relative;
  padding-bottom: 30px;
  margin: 20px 10px 30px;
}

.hp-tee-list__img {
  max-width: 250px;
  width: 100%;

  @media (min-width: 350px) {
    max-width: 300px;
  }
}

.hp-tee-list__link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: $hacker-blue;
}
