.hp-btn {
  background-color: $bright-blue;
  color: white;
  font-size: $font-size-large;
  display: inline-block;
  border-radius: 5px;
  padding: 12px 20px;
  text-decoration: none;
  font-weight: 700;
  border: none;
}
