@import "codemirror/lib/codemirror.css";
@import "codemirror/theme/monokai.css";

hp-editor {
  display: block;
  margin-top: 20px;
}

.hp-editor {
  border-top: 1px solid $light-blue-grey;
  border-bottom: 1px solid $light-blue-grey;
  min-height: 500px;
  max-width: 1000px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin: 0 -20px;
  overflow-x: auto;

  @media (min-width: 600px) {
    margin: 0;
    width: calc(100vw - 40px);
    border-radius: 5px;
    border: 1px solid $light-blue-grey;
  }
}

.hp-editor__header {
  display: flex;
  overflow-x: auto;
}

.hp-editor__header-col {
  flex: none;
}

.hp-editor__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: $off-white;
}

.hp-editor__code,
.hp-editor__preview {
  flex: 1;
  &[hidden] {
    display: none;
  }
}

.hp-editor__code {
  background-color: $hacker-blue;
  color: $off-white;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 10px 20px;
}

.hp-editor__field {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.hp-editor__label {
  display: block;
  text-transform: lowercase;
  padding-bottom: 5px;
}

.hp-editor__textarea {
  border: none;
  background-color: transparent;
  flex: 1;
  border: 1px solid $light-blue-grey;
  color: $off-white;
  font-size: $font-size-smaller;
  font-family: "dm";
  padding: 5px;
}

.hp-editor__preview {
  background-color: white;
}

.hp-editor__preview-item {
  min-height: 450px;
  overflow: hidden;
  display: flex;
  position: relative;

  &[hidden] {
    display: none;
  }
}

.hp-editor__preview-bg {
  flex: 1;
  display: flex;
  background-image: url("../assets/tshirt.png");
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.hp-editor__preview-zoom {
  transform: translate(var(--x), var(--y)) scale(var(--scale));
  transform-origin: 0 0;
  will-change: transform;
  flex: 1;
  display: flex;
}

.hp-editor__preview-controls {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
}

.hp-editor__zoom-title {
  margin: 10px 0;
  font-size: $font-size-smaller;
}

.hp-editor--2col {
  .hp-editor__body {
    flex-direction: row;
  }

  .hp-editor__header-col {
    flex: 1;
  }

  .hp-editor__code,
  .hp-editor__preview-item {
    width: 50%;
  }
}

.hp-editor__preview {
  position: absolute;
  top: 18%;
  left: 50%;
  width: 150px;
  transform: translateX(-50%);
  height: 200px;
  margin-left: 5px;
}

.hp-editor__preview-frame {
  background-color: transparent;
  border: 1px dashed $light-blue-grey;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.hp-editor--enhanced {
  .hp-editor__code {
    padding: 0 15px;
    flex: 1 1 100%;
    overflow: auto;
  }

  &.hp-editor--2col {
    .hp-editor__code {
      flex: 0 0 50%;
    }
  }

  .hp-editor__label {
    display: none;
  }

  .hp-editor__field {
    margin: 0;
  }

  .hp-editor__preview-controls {
    display: flex;
  }

  .CodeMirror {
    flex: 1;
  }

  .hp-editor__textarea {
    font-family: dm;
  }

  .cm-s-monokai.CodeMirror {
    background: $hacker-blue;
    color: $off-white;
    font-family: dm;
  }
  .cm-s-monokai .CodeMirror-gutters {
    background: $hacker-blue;
  }

  .cm-comment {
    font-style: italic;
  }
}

// Tablist

.hp-tablist {
  display: flex;
  padding-left: 20px;
}

.hp-tablist--bordered {
  border-left: 1px solid $off-white;
}

.hp-tablist__tab {
  line-height: 50px;
  display: block;
  margin-right: 20px;
  text-transform: lowercase;
  color: $dark-blue-grey;
}

.hp-tablist__tab[aria-selected="true"] {
  color: $hacker-blue;
  text-decoration: none;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 4px solid $hacker-blue;
  }
}

.hp-editor__form {
  position: relative;
}

.hp-editor__cta {
  @media (min-width: 500px) {
    position: absolute;
    right: 0;
    bottom: calc(100% - 20px);
  }
}

.hp-editor__footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}

.hp-editor__footer-btn {
  flex: 1;

  @media (min-width: 500px) {
    flex: none;
  }
}
