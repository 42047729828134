.hp-icon-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid $off-white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  touch-action: manipulation;

  &:active {
    background-color: $bright-blue;

    path {
      fill: white;
    }
  }

  &:hover {
    border-color: $hacker-blue;
  }
}

.hp-icon-btn--zoom-in {
  background-image: url("../assets/+.svg");
}
.hp-icon-btn--zoom-out {
  background-image: url("../assets/-.svg");
}
