.hp-logo {
  --size: 100px;
  width: var(--size);
  height: var(--size);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: calc(var(--size) / 20) solid #11182c;
  padding: calc(var(--size) / 20);
  margin-top: calc(var(--size) / -20);
  text-decoration: none;
  color: $hacker-blue;
}

.hp-logo__heading {
  margin: 0;
  font-size: calc(var(--size) / 4);
  font-weight: 900;
  line-height: 1;
  letter-spacing: -1px;
}

@media (min-width: 600px) {
  .hp-logo {
    --size: 200px;
  }
}
