html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
textarea,
button,
input {
  font-family: Lato, sans-serif;
}

body {
  color: $hacker-blue;
}

.hp-body {
  margin: 0;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
}

.hp-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .hp-header__btn {
    position: absolute;
    top: 40px;
    right: 40px;
  }

  @media (max-width: 600px) {
    .hp-header__btn {
      display: none;
    }
  }
}

.hp-content {
  flex-direction: column;
  flex: 1;
  display: flex;
  max-width: 1000px;
  margin: auto;
}

.hp-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .hp-body {
    padding-left: 20px;
    margin: 0;
  }
}

.hp-footer {
  text-align: center;
  padding: 20px;
  border-top: 1px solid $off-white;
  margin-left: -20px;
  margin-right: -20px;

  @media (min-width: 500px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.hp-footer__text {
  margin: 0;
  line-height: 1;
}
