.hp-beta-cta {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 40px;
  position: relative;
  padding: 30px 0 0;

  p:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: "";
    position: absolute;
    height: 40px;
    bottom: 100%;
    left: 50%;
    border-left: 2px solid $hacker-blue;
  }

  &::after {
    content: "";
    background-color: white;
    width: 10px;
    height: 10px;
    border: 2px solid $hacker-blue;
    position: absolute;
    bottom: 100%;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-4px) translateY(10px);
  }

  @media (min-width: 500px) {
    padding-bottom: 40px;

    &::before {
      height: 90px;
    }
  }
}

.hp-beta-cta__heading {
  font-size: 1.56rem;
  line-height: 1;
  margin: 0;

  &::before {
    content: "";
    background-color: white;
    width: 10px;
    height: 10px;
    border: 2px solid $hacker-blue;
    position: absolute;
    bottom: calc(100% + 40px);
    border-radius: 50%;
    left: 50%;
    transform: translateX(-4px) translateY(0px);
  }

  @media (min-width: 500px) {
    font-size: $font-size-bigger;
    margin: 10px 0;

    &::before {
      bottom: calc(100% + 90px);
    }
  }
}

.hp-beta-cta__text {
  line-height: 1;
  font-size: $font-size-smaller;

  @media (min-width: 500px) {
    font-size: $font-size-large;
  }
}

.hp-beta-cta__btn {
  display: block;

  @media (min-width: 500px) {
    display: inline-block;
  }
}
